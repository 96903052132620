var pwEye = document.getElementById("pw_eye");

pwEye.addEventListener('click', function() {
  var txtPass = document.getElementById("pw_field");
  if (txtPass.type === "text") {
    txtPass.type = "password";
    pwEye.className = "fa fa-eye";
  } else {
    txtPass.type = "text";
    pwEye.className = "fa fa-eye-slash";
  }
});

if (document.getElementById("confirm_eye") != null) {
  var confirmEye = document.getElementById("confirm_eye");

  confirmEye.addEventListener('click', function() {
    var txtPass = document.getElementById("confirm_field");
    if (txtPass.type === "text") {
      txtPass.type = "password";
      confirmEye.className = "fa fa-eye";
    } else {
      txtPass.type = "text";
      confirmEye.className = "fa fa-eye-slash";
    }
  });
}
